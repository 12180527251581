<div *ngIf="isLoggedIn">
  <mat-toolbar class="mat-elevation-z8">
    <button
      mat-icon-button
      *ngIf="sidenav.mode === 'over'"
      (click)="sidenav.toggle()">
      <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
      <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
    </button>
    LEGIT OFFICE
    <span class="example-spacer"></span>
    <div style="font-size: small;">Logged in as: {{userName}}</div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">
      <img class="avatar mat-elevation-z8" src="../../assets/images/LEGIT2.png"/>   
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/home" routerLinkActive="active">
        <mat-icon>home</mat-icon>
        <span>Home</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/timesheet" routerLinkActive="active">
        <mat-icon>punch_clock</mat-icon>
        <span>Timesheet</span>
      </button>
      <button mat-button class="menu-button" routerLink="/invoices" routerLinkActive="active">
        <mat-icon>document_scanner</mat-icon>
        <span>Invoices</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="menu-button" routerLink="/admin/projects" routerLinkActive="active">
        <mat-icon>apps</mat-icon>
        <span>Projects</span>
      </button>
      <button mat-button class="menu-button" routerLink="/admin/customers" routerLinkActive="active">
        <mat-icon>diversity_3</mat-icon>
        <span>Customers</span>
      </button>
      <button mat-button class="menu-button" routerLink="/admin/tasks" routerLinkActive="active">
        <mat-icon>add_task</mat-icon>
        <span>Tasks</span>
      </button>
      <mat-divider></mat-divider>
      <div *ngIf="hasRoleBank">
          <button mat-button class="menu-button" routerLink="/bank/transactions" routerLinkActive="active">
            <mat-icon>euro</mat-icon>
            <span>Transactions</span>
          </button>
          <button mat-button class="menu-button" routerLink="/bank/imports" routerLinkActive="active">
            <mat-icon>input</mat-icon>
            <span>Imports</span>
          </button>
          <mat-divider></mat-divider>
      </div>
      <button mat-button class="menu-button" (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="content mat-elevation-z8">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>  
</div>