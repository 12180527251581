import { LiveAnnouncer} from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { InvoiceDto, InvoiceSearch } from 'src/app/models/invoice';
import { DialogService } from 'src/app/services/dialog.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MatSelect } from '@angular/material/select';
import { MatAccordion } from '@angular/material/expansion';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { MatDrawer } from '@angular/material/sidenav';
//import { ChangeTimesheetComponent } from './change-timesheet/change-timesheet.component';
import { MatOption } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';
import { SimpleObject } from 'src/app/models/simple-object';
import { InvoiceService } from '../services/invoice.service';
import { NumberResultsComponent } from '../common/controls/number-results/number-results.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ChangeInvoiceComponent } from './change-invoice/change-invoice.component';
import { ListsService } from '../services/lists.service';
import { CommonService } from '../services/common.service';

const tsArr: InvoiceDto[] = [];

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})

export class InvoicesComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id','invoiceNumber','invoiceDate','customerName', 'description','payedDate','amount','tax' ,'totalAmount','fullyPayed', 'actions'];
  dataSource = new MatTableDataSource(tsArr);
  isLoading = false;
  searchData = {} as InvoiceSearch;

  customers!: SimpleObject[];

  payloadArray={
    enabled:false
  }

  constructor(private _liveAnnouncer: LiveAnnouncer, private listsService: ListsService, private invoiceService: InvoiceService, private dialogServie: DialogService, private _snackBar: MatSnackBar, private commonService: CommonService) {  

   this.initLists();   
    
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('selectedCustomerId') matSelectedCustomer!: MatSelect;
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('drawerContainer') drawerContainer!: MatDrawerContainer;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild(ChangeInvoiceComponent) changeInvoiceComponent!: ChangeInvoiceComponent;
  @ViewChild(NumberResultsComponent) numberResultsComponent!: NumberResultsComponent;

  initLists() {
    var dataCustomers = this.listsService.getCustomerList();

    if (!this.commonService.stringIsNullOrEmpty(dataCustomers))
    {
      var orgTCustomerList = JSON.parse(dataCustomers || '{}');  // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
      this.customers = orgTCustomerList as SimpleObject[];
    }
 }

 addDateFromEvent(event: MatDatepickerInputEvent<Date>) {
  if (event.value !== null)
  {
    var dt = new Date(event.value.toString());
    let UTCDate = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) - dt.getTimezoneOffset();
    this.searchData.invoiceDateFrom = new Date(UTCDate);    
  }   
 }

 addDateToEvent(event: MatDatepickerInputEvent<Date>) {
  if (event.value !== null)
  {
    var dt = new Date(event.value.toString());
    let UTCDate = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) - dt.getTimezoneOffset();
    this.searchData.invoiceDateTo = new Date(UTCDate);    
  }   
}
  async ngAfterViewInit() {   
    this.accordion.openAll();        
    this.matSelectedCustomer.valueChange.subscribe(value => {
        this.searchData.customerId = value;
    });   
    
    
  }

  clearSearchFields() {
     this.searchData = {} as InvoiceSearch;
     this.matSelectedCustomer.options.forEach((data: MatOption) => data.deselect());
     this.dataSource = new MatTableDataSource([] as InvoiceDto[]);
  }

  search()
  {    
      this.isLoading = true;

      const params: InvoiceSearch = {
        "invoiceNumber": this.searchData.invoiceNumber,
        "description": this.searchData.description,
        "invoiceDateFrom": this.searchData.invoiceDateFrom,
        "invoiceDateTo": this.searchData.invoiceDateTo,
        "customerId": this.searchData.customerId,
        "minimumAmount": null,
        "maximumAmount": null,
        "results": this.numberResultsComponent.getSelectedValue()
      }

      this.invoiceService.search(params)
    .subscribe(response => {
        this.dataSource = new MatTableDataSource(response.body as InvoiceDto[]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.accordion.closeAll();
        this.isLoading = false;
        }, error => {
            alert('searchInvoices: An unexpected error occurred.' + error);
            this.isLoading = false;
            console.log(error);
        });       
  }

  ngOnInit(): void {
    this.invoiceService.invoiceChanged.subscribe(invoice => {
      const index = this.dataSource.data.findIndex((item) => item.id === invoice.id);
      if (index > -1) {
        this.dataSource.data[index] = invoice;
        this.dataSource.paginator = this.paginator;
    }
      this.drawer.close();
      this._snackBar.open("Invoice saved." , "close", { duration: 5000 });
    });  

    this.invoiceService.invoiceNew.subscribe(invoice => {
      var data = this.dataSource.data;
      data.push(invoice);

      this.dataSource = new MatTableDataSource(data as InvoiceDto[]);

      this.dataSource.paginator = this.paginator;    
      this.dataSource.sort = this.sort;

      this.drawer.close();
      this._snackBar.open("Invoice created." , "close", { duration: 5000 });
    });

    this.invoiceService.invoiceError.subscribe(message => {    
      this._snackBar.open(message, "close", { verticalPosition: 'top'});
    });

    this.invoiceService.invoiceDeleted.subscribe(id => {    
      var deletedElementIdx = this.dataSource.data.findIndex(t => t.id === id);
      var data = this.dataSource.data;         
      this.dataSource.data = data.filter((i, index) => index !== deletedElementIdx);   ;
      this.dataSource.paginator = this.paginator;    
      this.dataSource.sort = this.sort;

      this._snackBar.open("Invoice deleted.", "close", { duration: 5000 });
    });    
  }

  delete(id: any) {
    this.dialogServie.confirmDialog({
      title: 'Confirm please',
      message: 'Are you sure you want to delete invoice ' + id + '?',
      confirmText: 'Yes',
      cancelText: 'No'
    }).subscribe(data => 
      { 
        if (data == true)
        {
          console.log('DELETE: ' + id);
          this.invoiceService.delete(id);
        }
        else
          console.log('do not delete');
      });    
  }

  addNew() {    
    this.changeInvoiceComponent.new(this.drawer);
  }

  edit(invoice: InvoiceDto) {
    this.changeInvoiceComponent.bind(invoice, this.drawer);
}

checkInvoice(invoice : InvoiceDto) : boolean {
  return invoice.isDownloadable as boolean;
  //return false;
}

download(invoice: InvoiceDto) {  
  if (invoice.isDownloadable)  
  this.invoiceService.download(invoice.id).subscribe(response => {
    let blob:Blob=response.body as Blob;
    let a = document.createElement('a');

    if (invoice.filename !== undefined)
    {
      a.download = invoice.filename;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    }
  })
}

formatDate(srcDate: Date) {  
  return srcDate!== null ? new Date(srcDate).toLocaleDateString('nl-NL') : '-';
}

 /** Announce the change in sort state for assistive technology. */
 announceSortChange(sortState: Sort) {
  // This example uses English messages. If your application supports
  // multiple language, you would internationalize these strings.
  // Furthermore, you can customize the message to add additional
  // details about the values being sorted.
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
}

}
