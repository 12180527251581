import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor() { }

  public stringIsNullOrEmpty(field: string | null) : boolean {
     return field === null || field === undefined || field === '' || field === 'undefined';
  }

  public formatToNlDisplayDate(srcDate: Date) : string {
    return srcDate === null ? '' : new Date(srcDate).toLocaleDateString('nl-NL');
  }

  public numberGreaterZero(field: number): boolean {
    var result = 
        field !== null 
        && field !== undefined 
        && field > 0;

        return result;
  }

  public stringNumberGreaterZero(field: string) : boolean {
    var result = 
        field !== null 
        && field !== undefined 
        && field !== 'NaN'
        && field !== ''
        && field !== '0'
        && Number(field) > 0;

        return result;
  }

  public resetValues(oldObject: any, savedValuesObject: any)
  {  
    let keysOldObject = Object.keys(oldObject);
    let keysSavedValuesObject = Object.keys(savedValuesObject);

    for (let oldObjectKey of keysOldObject) {
      //check if key also exists in copied-object and then compare values
      let savedValuesObjectKey = keysSavedValuesObject.find(c => c === oldObjectKey)

      if (savedValuesObjectKey !== null && savedValuesObjectKey !== undefined)
      { 
        //key exists! now compare values
        let attributeValueOldObject = oldObject[oldObjectKey as keyof typeof oldObject];
        let attributeValueCopiedObect = savedValuesObject[savedValuesObjectKey as keyof typeof savedValuesObject];

        if (attributeValueOldObject !== attributeValueCopiedObect)
            oldObject[oldObjectKey as keyof typeof oldObject] = attributeValueCopiedObect;

      }
    }
  }
}