  <form [formGroup]="form" (submit)="onPost()" class="form-signin w-100 m-auto">
    <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

    <div class="form-floating">
      <input formControlName="username" name="username" type="email" class="form-control" placeholder="name@example.com">
      <label for="username">Email address</label>
    </div>
    <div class="form-floating">
      <input formControlName="password" type="password" name="password"  class="form-control" placeholder="Password">
      <label for="password">Password</label>
    </div>    
    <button class="w-100 btn btn-lg btn-primary actionButton" type="submit">Sign in</button>
    <div class="msg" *ngIf="status">
      {{status.message}}
  </div>
  </form>
  