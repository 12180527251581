import { Component, Input, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { SimpleObject } from 'src/app/models/simple-object';

@Component({
  selector: 'legit-autocomplete',
  templateUrl: './legit-autocomplete.component.html',
  styleUrls: ['./legit-autocomplete.component.css']
})
export class LegitAutocompleteComponent {
  itemCtrl!: FormControl;
  filteredItems!: Observable<any[]>;
  @Input() labelName = ''; 
  @Input() items!: SimpleObject[]; 
  @Input() isRequired!: boolean;
  @Input() withTopMargin!: boolean;

  selectedItem = <SimpleObject>{ id:0, name: ''};

  itemSelectionChanged: EventEmitter<SimpleObject> = new EventEmitter<SimpleObject>();

constructor() {
  this.itemCtrl = new FormControl();
    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(''),
      map((state) => (state ? this.filterStates(state) : this.items.slice()))
    );  
}

@ViewChild('matAutocomplete') matSelectItem!: MatAutocomplete;
@ViewChild(MatAutocompleteTrigger) _auto!: MatAutocompleteTrigger;

filterStates(name: string): SimpleObject[] {
  return this.items.filter(
    (state) => state.name.toLowerCase().indexOf(name.toLowerCase()) === 0
  );
}

inputChange() {
 if (this.itemCtrl.value === "")
 {
    this.selectedItem = <SimpleObject>{ id:0, name: ''};  
    this.itemSelectionChanged.emit(this.selectedItem);
 }
}

onEnter(evt: any) {
  if (evt.source.selected) {    
    this.selectedItem = this.findSelectedItem(evt.source.value);
    this.itemSelectionChanged.emit(this.selectedItem);
  }
}

private findSelectedItem(itemValue:string) : any
{
  var item : any;

  if (itemValue !== null)
    item = this.items.find(p => p.name === itemValue);

  return item;
}

clearSelecedItem()
{
  this.itemCtrl.reset('');
  this.selectedItem = <SimpleObject>{ id:0, name: ''};  
}

getSelectedItem() : SimpleObject
{  
  return this.selectedItem;
}

setInitialValue(itemValue: string)
{
    this.selectedItem = this.findSelectedItem(itemValue);
    this.itemSelectionChanged.emit(this.selectedItem);
    var option = this._auto.autocomplete.options.find(o => o.value == this.selectedItem.name);
    this.itemCtrl.setValue(option?.value);
}

isItemSelected () : boolean {
  return !this.isRequired 
        ||  (this.isRequired 
             && this.selectedItem !== null 
             && this.selectedItem !== undefined
             && this.selectedItem.id > 0);
}

}
