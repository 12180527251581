import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { environment } from 'src/environments/environment';
import { CustomerUpdate, CustomerDto } from '../models/customer';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {
  private baseUrl = environment.baseUrl + '/customer';
  
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  
  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  customerError: EventEmitter<string> = new EventEmitter<string>();
  customerChanged: EventEmitter<CustomerDto> = new EventEmitter<CustomerDto>();
  customerNew: EventEmitter<CustomerDto> = new EventEmitter<CustomerDto>();
  customerDeleted: EventEmitter<number> = new EventEmitter();

  constructor(private http: HttpClient) {   }
  
  search(id: string, name: string, address: string, numberResults: number) {    
    var customerSearchUrl = 
    '?id=' + id 
    + '&name=' + name 
    + '&address=' + address
    + '&results=' + numberResults;

    return this.http.get(this.baseUrl + customerSearchUrl) .pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    }));
  }  

  async update(customer: CustomerDto) { 
    const body: CustomerUpdate =  {
      "id": customer.id, 
      "name": customer.name, 
      "address": customer.address,
      "houseNumber": customer.houseNumber,
      "city": customer.city,
      "zip": customer.zip,
      "invoiceTo": customer.invoiceTo,
      "creationUserId": 0, 
      "changeUserId": customer.changeUserId
    }
    const source$ = this.http.put(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));    
    this.customerChanged.emit(firstResult as CustomerDto);   
    }
    
    handleError(error: HttpErrorResponse) {
      this.customerError.emit('Customer-service error: ' + error.error);
    }

  async create(customer: CustomerDto) { 

    const body: CustomerUpdate =  {
      "id": 0, 
      "name": customer.name, 
      "address": customer.address,
      "houseNumber": customer.houseNumber,
      "city": customer.city,
      "zip": customer.zip,
      "invoiceTo": customer.invoiceTo,
      "creationUserId": customer.creationUserId, 
      "changeUserId": 0
    }
    
    const source$ = this.http.post(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch(this.handleError);
    this.customerNew.emit(firstResult as CustomerDto); 
    }

    delete(id: string) {  
      return this.http.delete(this.baseUrl + '/' + Number(id), {headers: this.headers}).subscribe((response) => {      
        this.customerDeleted.emit(Number(response));
        console.log('Customer deleted: ' + response);
      });
    }  

  async getList() {     
    const source$ = this.http.get(this.baseUrl + '/getcustomerlist');
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));        
    return firstResult;
  }
}
