import { state } from "@angular/animations";

export enum List {
    tasks = 'TASKLIST',
    projects = 'PROJECTLIST',
    customers = 'CUSTOMERLIST',
    accounts = 'ACCOUNTLIST',
    categories = 'CATEGORYLIST'
  }

  export enum AuthenticationData {
    userId = 'USERID',
    username = 'USERNAME',
    fullname = 'FULLNAME',
    accesstoken = 'ACCESSTOKEN',
    refreshtoken = 'REFRESHTOKEN',
    isPrivateBank = 'PRIVATEBANK'
  }

  export enum Roles {
    maintenance = 'BEHEER',
    bank = 'BANK',
    reader = 'LEZER',
    writer = 'SCHRIJVER',
    privateBank = 'PRIVATEBANK'
  }

