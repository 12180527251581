<mat-drawer-container class="edit-timesheet-container" autosize hasBackdrop="true" disableClose="true" position="end">
    <mat-drawer #drawer class="timesheet-sidenav" mode="over">    
      <change-timesheet></change-timesheet>
    </mat-drawer>  
  <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel class="searchPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            search
          </mat-panel-title>
          <mat-panel-description>
            enter search criteria
            <mat-icon>search</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>  

        <table class="full-width" cellspacing="0">
          <tr>
            <td class="firstSearchColumn">
              <mat-form-field appearance="outline" >
                <mat-label>Description</mat-label>
                <input matInput [(ngModel)]="searchData.description">
              </mat-form-field>
            </td>
            <td class="secondSearchColumn">
              <mat-form-field appearance="outline">
                <mat-label>User</mat-label>
                <mat-select #selectedUserId>
                  <mat-option *ngFor="let user of users" [value]="user.id">
                    {{user.fullName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td class="thirdSearchColumn">
              <number-results></number-results>
            </td>
            <td class="fourthSearchColumn"></td>
          </tr>
          <tr>
            <td>             
              <legit-autocomplete #projectAutoComplete labelName="Project" [items]="projects" [isRequired]="false" [withTopMargin]="true" class="secondRowSearchItems"></legit-autocomplete>
            </td>
            <td>              
              <legit-autocomplete #taskAutoComplete labelName="Task" [items]="tasks" [isRequired]="false" [withTopMargin]="true" class="secondRowSearchItems"></legit-autocomplete>
            </td>
            <td>
              <div class="searchButton">
              <button mat-raised-button (click)="clearSearchFields();" class="leftActionButton">clear</button>             
              <button mat-raised-button (click)="search()" class="actionButton">search</button>             
            </div>
          </td>
          <td></td>
          </tr>
       </table>
        <!-- <div class="searchButton">
          <button mat-raised-button (click)="clearSearchFields();" class="leftActionButton">clear</button>             
          <button mat-raised-button (click)="search()" class="actionButton">search</button>             
        </div> -->
      </mat-expansion-panel>        
    </mat-accordion>
  
  <div class="mat-elevation-z8 tableContent">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
              Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            Date
            </th>
            <td mat-cell *matCellDef="let element"> {{formatDate(element.date)}}</td>
         </ng-container>
          
          <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer">
              User
              </th>
              <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
          </ng-container>
          
          <ng-container matColumnDef="task">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer">
            Task
            </th>
            <td mat-cell *matCellDef="let element"> {{element.taskName}} </td>
        </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            Description
            </th>
            <td mat-cell *matCellDef="let element"> {{element.description}}</td>
        </ng-container>

        <ng-container matColumnDef="noHours">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            Hours
            </th>
            <td mat-cell *matCellDef="let element"> {{element.noHours}}</td>
        </ng-container>
         
          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>              
                  <mat-icon (click)="addNew()" aria-label="Example icon-button with a heart icon" ngbTooltip="create timesheet" class="actionIcon">add</mat-icon>              
                </th>
        
              <td mat-cell *matCellDef="let element; let i=index;">
                  <mat-icon (click)="edit(element)" aria-label="Edit" class="actionIcon" ngbTooltip="edit timesheet">edit</mat-icon>
                 <mat-icon (click)="delete(element['id'])" aria-label="Delete" ngbTooltip="delete timesheet" class="actionIcon" color="warn">delete</mat-icon>
                </td>
            </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
          <mat-progress-spinner    
              mode="indeterminate">
          </mat-progress-spinner>
      </div>
      <mat-paginator [pageSize]="15" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
  </div> 
</mat-drawer-container>