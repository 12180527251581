<mat-card style="width: 400px;">
    <mat-card-content>
        <table class="example-full-width" cellspacing="0">
            <tr><td>
                <mat-form-field appearance="outline">
                    <mat-label>Date</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="this.timesheet.date">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-hint *ngIf="timesheet.date === null || timesheet.date === undefined">Date required</mat-hint>
                </mat-form-field>
            </td></tr>
        <tr><td>
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput placeholder="description" [(ngModel)]="this.timesheet.description" (change)="allRequiredFieldsFilled()">
                <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(timesheet.description)">Description required</mat-hint>
              </mat-form-field>
        </td></tr>
        <tr><td>
            <mat-form-field appearance="outline">
                <mat-label>User</mat-label>
                <mat-select #selectedUserId (valueChange)="userSelectionChanged()">
                  <mat-option *ngFor="let user of users" [value]="user.id">
                    {{user.fullName}}
                  </mat-option>
                </mat-select>                
              </mat-form-field>
        </td></tr>
        <tr><td>
            <mat-form-field appearance="outline">
                <mat-label>Time</mat-label>
                <input matInput placeholder="description" type="number" [(ngModel)]="this.timesheet.noHours" (change)="allRequiredFieldsFilled()">
                <mat-hint *ngIf="!this.commonService.numberGreaterZero(timesheet.noHours)">Hours > 0 required</mat-hint>
              </mat-form-field>
        </td></tr>
        <tr><td>
            <legit-autocomplete #tasksAutoComplete labelName="Task" [items]="tasks" [isRequired]="true" [withTopMargin]="false"></legit-autocomplete>
        </td></tr>
        <tr>
            <td><div style="font-size: small;margin-top:-15px;margin-left:5px;">Project: {{project}}</div><div style="font-size: small;margin-left:5px;">Customer: {{customer}}</div></td>
        </tr>       
    </table>
       <div class="searchButton">
        <button mat-raised-button class="leftActionButton" (click)="closeDrawer()">cancel</button>             
        <button mat-raised-button class="actionButton" [disabled]="!allFieldsFilled" (click)="save()">save</button>             
      </div> 
    </mat-card-content>
</mat-card>
