import { Component, AfterViewInit, OnInit } from '@angular/core';
import { CustomerDto } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { MatDrawer } from '@angular/material/sidenav';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'change-customer',
  templateUrl: './change-customer.component.html',
  styleUrls: ['./change-customer.component.css']
})

export class ChangeCustomerComponent implements OnInit, AfterViewInit{  
  constructor(private customerService: CustomerService, public commonService: CommonService) {
  }

  matDrawer!: MatDrawer;
  customer = {
    "id": 0,  
    "name": '',  
    "address": '',
    "zip": '',
    "city": '',
    "houseNumber": '',
    "invoiceTo":''
  } as CustomerDto 

  oldCustomer = {};
  allFieldsFilled = false;

  ngOnInit(): void {    
  }
  
  ngAfterViewInit(): void {    
  }

  bind(customer: CustomerDto, drawer: MatDrawer) {
    this.matDrawer = drawer;
    
    if (customer != null)         
    {
        this.customer = customer;
        this.oldCustomer = JSON.parse(JSON.stringify(customer)); //Save current values in case update is cancelled.
    }
    
    this.allRequiredFieldsFilled();
    this.matDrawer.open();
   }

   new (drawer: MatDrawer) {
    this.matDrawer = drawer; 
    this.customer =  {
        "id": 0,
        "name": '',       
        "address": '',
        "zip": '',
        "city": '',
        "houseNumber": '',
        "invoiceTo":''
    } as CustomerDto

    this.allRequiredFieldsFilled();  
    this.matDrawer.open();
   }
  
   save() {   
    var retValue: any;
    var newObject = this.customer.id === 0;    

    retValue = newObject ? this.customerService.create(this.customer) : this.customerService.update(this.customer);    
  }

  allRequiredFieldsFilled () {
    this.allFieldsFilled =
     this.customer.name !== null && this.customer.name !== undefined && this.customer.name !== ''
     && this.customer.address !== null && this.customer.address !== undefined && this.customer.address !== ''
     && this.customer.zip !== null && this.customer.zip !== undefined && this.customer.zip !== ''
     && this.customer.city !== null && this.customer.city !== undefined && this.customer.city !== ''
     && this.customer.houseNumber !== null && this.customer.houseNumber !== undefined && this.customer.houseNumber !== ''
     && this.customer.invoiceTo !== null && this.customer.invoiceTo !== undefined && this.customer.invoiceTo !== '';
   
     return this.allFieldsFilled;      
   }

   closeDrawer() {
    this.commonService.resetValues(this.customer, this.oldCustomer); //For restoring values in the table-view
    this.matDrawer.close();
   }   
}
