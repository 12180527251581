import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule} from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialComponentsModule } from './material-components/material-components.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BsNavbarComponent } from './bs-navbar/bs-navbar.component';
import { HomeComponent } from './home/home.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ImportsComponent } from './imports/imports.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { AdminTasksComponent } from './admin/admin-tasks/admin-tasks.component';
import { AdminProjectsComponent } from './admin/admin-projects/admin-projects.component';
import { AdminCustomersComponent } from './admin/admin-customers/admin-customers.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ProjectService } from './services/project.service';
import { CustomerService } from './services/customer.service';
import { AuthInterceptor } from './interceptors/AuthInterceptor ';
import { ConfirmComponent } from './confirm/confirm.component';
import { MAT_DATE_LOCALE} from '@angular/material/core';
import { ChangeProjectComponent } from './admin/admin-projects/change-project/change-project.component';
import { ChangeTimesheetComponent } from './timesheet/change-timesheet/change-timesheet.component';
import { LegitAutocompleteComponent } from './common/controls/legit-autocomplete/legit-autocomplete.component';
import { NumberResultsComponent } from './common/controls/number-results/number-results.component';
import { ChangeInvoiceComponent } from './invoices/change-invoice/change-invoice.component';
import localeNL from '@angular/common/locales/nl'
import { registerLocaleData } from '@angular/common';
import { UploadInvoiceComponent } from './common/controls/upload-invoice/upload-invoice.component';
import { ChangeCustomerComponent } from './admin/admin-customers/change-customer/change-customer.component';
import { ChangeTaskComponent } from './admin/admin-tasks/change-task/change-task.component';
import { InvoiceService } from './services/invoice.service';
import { TaskService } from './services/task.service';
import { TimesheetService } from './services/timesheet.service';
import { AuthenticationService } from './services/authentication.service';
import { CommonService } from './services/common.service';
import { ListsService } from './services/lists.service';
import { BankTransactionComponent } from './bank/bank-transaction/bank-transaction.component';
import { BankImportComponent } from './bank/bank-import/bank-import.component';
import { ChangeImportComponent } from './bank/bank-import/change-import/change-import.component';
import { ChangeTransactionComponent } from './bank/bank-transaction/change-transaction/change-transaction.component';
registerLocaleData(localeNL);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BsNavbarComponent,
    HomeComponent,
    InvoicesComponent,
    ImportsComponent,
    TimesheetComponent,
    AdminTasksComponent,
    AdminProjectsComponent,
    AdminCustomersComponent,
    ConfirmComponent,
    ChangeProjectComponent,
    ChangeProjectComponent,
    ChangeTimesheetComponent,
    LegitAutocompleteComponent,
    NumberResultsComponent,
    ChangeInvoiceComponent,
    UploadInvoiceComponent,
    ChangeCustomerComponent,
    ChangeTaskComponent,
    BankTransactionComponent,
    BankImportComponent,
    ChangeImportComponent,
    ChangeTransactionComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialComponentsModule,
    MatPaginatorModule,
    BrowserAnimationsModule
  ],
  providers: [ProjectService, CustomerService, InvoiceService, TaskService, TimesheetService, AuthenticationService, CommonService, ListsService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },{provide: LocationStrategy, useClass: HashLocationStrategy},{provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},{ provide: LOCALE_ID, useValue: "nl-NL" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
