<mat-drawer-container class="edit-import-container" autosize hasBackdrop="true" disableClose="true" position="end">
    <mat-drawer #drawer class="import-sidenav" mode="over">    
      <app-change-import></app-change-import>
    </mat-drawer>  
  <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel class="searchPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            search
          </mat-panel-title>
          <mat-panel-description>
            enter search criteria
            <mat-icon>search</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>  
  
        <table class="full-width" cellspacing="0">
        <tr>
            <td class="firstSearchColumn">
                <mat-form-field appearance="outline">
                    <mat-label>Date from</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="searchData.dateFrom" (dateChange)="addDateFromEvent($event)">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
            <td class="secondSearchColumn">
                <mat-form-field appearance="outline">
                    <mat-label>Date to</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="searchData.dateTo" (dateChange)="addDateToEvent($event)">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </td>
            <td class="thirdSearchColumn">
                <mat-form-field appearance="outline">
                    <mat-label>Filename</mat-label>
                    <input matInput [(ngModel)]="searchData.fileName">
                </mat-form-field>
            </td>
            <td class="thirdSearchColumn">
                <number-results></number-results>
            </td>            
        </tr>       
      </table>
      <div [hidden]='!userHasRole()' style="float:left;">
        <mat-checkbox [(ngModel)]="isPrivate" (change)="changeConnection($event)">private</mat-checkbox>
      </div>
        <div class="searchButton">
          <button mat-raised-button (click)="clearSearchFields();" class="leftActionButton">clear</button>             
          <button mat-raised-button (click)="search()" class="actionButton">search</button>             
        </div>
      </mat-expansion-panel>        
    </mat-accordion>
  
  <div class="mat-elevation-z8 tableContent">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
              Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
          
          <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creationDate">
              Date import
            </th>
            <td mat-cell *matCellDef="let element"> {{formatDate(element.creationDate)}} </td>
        </ng-container>
        
          <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by filename">
                Filename
              </th>
              <td mat-cell *matCellDef="let element"> {{element.filename}} </td>
          </ng-container>         
                   
          <ng-container matColumnDef="creationUserName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creationUserName">
                Created by
              </th>
              <td mat-cell *matCellDef="let element"> {{element.creationUserName}} </td>
          </ng-container>
          
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
  
        <td mat-cell *matCellDef="let element; let i=index;" style="text-align: center;">            
            <mat-icon (click)="download(element)" aria-label="Download" ngbTooltip="download import" class="actionIcon">download</mat-icon>
          </td>
      </ng-container> 

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
          <mat-progress-spinner    
              mode="indeterminate">
          </mat-progress-spinner>
      </div>
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
  </div>
  </mat-drawer-container>
