import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { ImportSearch } from '../models/import';

@Injectable({
  providedIn: 'root'
})

export class BankImportService {

  constructor(private http: HttpClient) { }

  private baseUrl = environment.baseUrl + '/bankimport';

  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  importError: EventEmitter<string> = new EventEmitter<string>();

  search (searchData: ImportSearch) {
    let params = new HttpParams();
    params = params.append("dateFrom", searchData.dateFrom === null || searchData.dateFrom === undefined ? '' : searchData.dateFrom.toString());
    params = params.append("dateTo", searchData.dateTo === null || searchData.dateTo === undefined ? '' : searchData.dateTo.toString());
    params = params.append("fileName", searchData.fileName === null || searchData.fileName === undefined ? '' : searchData.fileName.toString());
    params = params.append("isPrivateAccount", searchData.isPrivateAccount === null || searchData.isPrivateAccount === undefined ? '' : searchData.isPrivateAccount);
    params = params.append("results", searchData.results);

    return this.http.get(this.baseUrl, { observe: "response", params }).pipe(catchError( (error: HttpErrorResponse) => {
        if (error.status === 404)
          return throwError(() => new MyNotFoundError(error))       
        else
          return throwError(() => new AppError(error))
      }));
  }

  public download(importId: number)
  {
    var downloadUrl = this.baseUrl + '/download?importId=' + importId;
    return this.http.get(downloadUrl, {observe: 'response', responseType: 'blob'})
  }
}
