import { LiveAnnouncer} from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectDto,ProjectSearch } from 'src/app/models/project';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSelect } from '@angular/material/select';
import { MatAccordion } from '@angular/material/expansion';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { MatDrawer } from '@angular/material/sidenav';
import { ChangeProjectComponent } from './change-project/change-project.component';
import { MatOption } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NumberResultsComponent } from 'src/app/common/controls/number-results/number-results.component';
import { SimpleObject } from 'src/app/models/simple-object';
import { ListsService } from 'src/app/services/lists.service';
import { CommonService } from 'src/app/services/common.service';

const projArr: ProjectDto[] = [];

@Component({
   selector: 'admin-projects',
   templateUrl: './admin-projects.component.html',
   styleUrls: ['./admin-projects.component.css']
})
export class AdminProjectsComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ['id','name','customer','isFinished','dateFinished', 'creationUser', 'actions'];
  dataSource = new MatTableDataSource(projArr);
  isLoading = false;
  
  searchData = {
    // "id": 0,
    // "name": '',
    // "isFinished": false,
    // "customerId":,
    // "dateFinished": '',
    // "results" : 10,
  } as ProjectSearch;

  customers!: SimpleObject[];
  
  public selectedProject!: ProjectDto;

  constructor(private _liveAnnouncer: LiveAnnouncer, private listsService: ListsService, private service: ProjectService, private dialogServie: DialogService, private _snackBar: MatSnackBar, private commonService: CommonService) {
    this.initLists();
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('selectedCustomerId') matSelectCustomer!: MatSelect;
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('drawerContainer') drawerContainer!: MatDrawerContainer;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild(ChangeProjectComponent) changeProjectComponent!: ChangeProjectComponent;
  @ViewChild(NumberResultsComponent) numberResultsComponent!: NumberResultsComponent;

  initLists() {
    var dataCustomers = this.listsService.getCustomerList();

    if (!this.commonService.stringIsNullOrEmpty(dataCustomers))
    {
      var orgTCustomerList = JSON.parse(dataCustomers || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
      this.customers = orgTCustomerList as SimpleObject[];
    }
 }

 ngOnInit(): void {
  this.service.projectChanged.subscribe(project => {
    const index = this.dataSource.data.findIndex((item) => item.id === project.id);
    if (index > -1) {
      this.dataSource.data[index] = project;
      this.dataSource.paginator = this.paginator;
  }
    this.drawer.close();
    this._snackBar.open("Project saved." , "close", { duration: 5000 });
    this.listsService.updateProjectList(project.id, project.name);
  });
  
  this.service.projectNew.subscribe(project => {
    this.dataSource.data.push(project);
    this.dataSource.paginator = this.paginator;      
    this.drawer.close();
    this._snackBar.open("Project created." , "close", { duration: 5000 });
    this.listsService.updateProjectList(project.id, project.name);
  });

  this.service.projectError.subscribe(message => {    
    this._snackBar.open(message, "close", { verticalPosition: 'top'});
  });          
}

  ngAfterViewInit() {
    this.accordion.openAll();
    this.matSelectCustomer.valueChange.subscribe(value => {
      this.searchData.customerId = value;
  });
  }

  clearSearchFields() {
    this.searchData = {} as ProjectSearch;
    this.matSelectCustomer.options.forEach((data: MatOption) => data.deselect());    
    this.dataSource = new MatTableDataSource([] as ProjectDto[]);
  }

  search()
  {
    this.isLoading = true;

      //var projectId = this.searchData.id == null ? '' : this.searchData.id.toString();
      //var customerId = this.searchData.customerId == null ? '' : this.searchData.customerId.toString();
      //var name = this.searchData.name == null ? '' : this.searchData.name
      //var numberResults = this.numberResultsComponent.getSelectedValue();
      this.searchData.results = this.numberResultsComponent.getSelectedValue();

      this.service.search(this.searchData)
    .subscribe(response => {
        this.dataSource = new MatTableDataSource(response as ProjectDto[]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.accordion.closeAll();
        this.isLoading = false;
        }, error => {
            alert('searchProjects: An unexpected error occurred.');
            this.isLoading = false;
            console.log(error);
        });
  }  

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();

  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

  formatDate(srcDate: Date) {
    return srcDate === null ? '' : new Date(srcDate).toLocaleDateString('nl-NL');
  }

  addNew() {
    this.changeProjectComponent.new(this.drawer);
  }

  delete(id: any) {
    //console.log('delete pressed for: ' + id);
    this.dialogServie.confirmDialog({
      title: 'Confirm please',
      message: 'Are you sure you want to delete project ' + id + '?',
      confirmText: 'Yes',
      cancelText: 'No'
    }).subscribe(data => 
      { 
        if (data == true)
          console.log('DELETE');
        else
          console.log('do not delete');
      });    
  }

  edit(project: ProjectDto) {
      this.changeProjectComponent.bind(project, this.drawer);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}