import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatDrawerContainer, MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NumberResultsComponent } from 'src/app/common/controls/number-results/number-results.component';
import { SimpleObject } from 'src/app/models/simple-object';
import { TransactionDto, TransactionSearch } from 'src/app/models/transaction';
import { ListsService } from 'src/app/services/lists.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { ChangeTransactionComponent } from './change-transaction/change-transaction.component';
import { CommonService } from 'src/app/services/common.service';
import { MatOption } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Roles } from 'src/app/common/enums';

const transactionArr: TransactionDto[] = [];

@Component({
  selector: 'bank-transaction',
  templateUrl: './bank-transaction.component.html',
  styleUrls: ['./bank-transaction.component.css']
})

export class BankTransactionComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ['id','accountNumber','valueDate','amount','saldo','referenceName', 'reference', 'categoryName','description', 'actions'];
  dataSource = new MatTableDataSource(transactionArr);
  isLoading = false;
  isPrivate = this.authenticationService.isPrivateBank();  
  searchData = { } as TransactionSearch;
  categories!: SimpleObject[];
  accounts!: SimpleObject[];
  validationEnabled = false;
  importEnabled = false;
  validationSource = [] as TransactionDto[];
  importIsValid = false;

constructor(private _liveAnnouncer: LiveAnnouncer, private listsService: ListsService, private service: TransactionService, private commonService: CommonService, private authenticationService: AuthenticationService, private _snackBar: MatSnackBar) {  
  this.initLists();
}

@ViewChild(MatSort) sort!: MatSort;
@ViewChild(MatPaginator) paginator!: MatPaginator;
@ViewChild(MatAccordion) accordion!: MatAccordion;
@ViewChild('selectedAccountId') matSelectAccount!: MatSelect;
@ViewChild('selectedCategoryId') matSelectCategory!: MatSelect;
@ViewChild('drawerContainer') drawerContainer!: MatDrawerContainer;
@ViewChild('drawer') drawer!: MatDrawer;
@ViewChild(ChangeTransactionComponent) changeTransactionComponent!: ChangeTransactionComponent;
@ViewChild(NumberResultsComponent) numberResultsComponent!: NumberResultsComponent;
@ViewChild('fileInput') fileInput!: { nativeElement: { click: () => void; files: { [key: string]: File; }; }; };

file: File | null = null;

ngOnInit(): void {
  this.refreshLists();
}

getFile(): void {
  this.fileInput.nativeElement.click();
}

onChangeFileInput(): void {
  this.dataSource = new MatTableDataSource([] as TransactionDto[]);
  this.isLoading = true;
  const files: { [key: string]: File } = this.fileInput.nativeElement.files;
  this.file = files[0];
  if (this.file !== null  && this.file !== undefined)
  {    
    this.validationEnabled = true;

    let fileToUpload = this.file;
    const formData = new FormData();
    if (fileToUpload !== null && fileToUpload !== undefined) {
        formData.append('file', fileToUpload, fileToUpload.name);      
        this.service.validateTransactions(this.isPrivate, formData).then( (data) => {
          
          this.validationSource = data as TransactionDto[];       
          this.dataSource = new MatTableDataSource(this.validationSource);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          
          var error = false;
          var warning = false;
          this.validationSource.forEach(r => {
            if (r.validationMessage !== null && r.validationMessage !== undefined && r.validationMessage.indexOf('ERROR') !== -1)
              error = true;
            if (r.validationMessage !== null && r.validationMessage !== undefined && r.validationMessage.indexOf('WARNING') !== -1)
              warning = true;
        
          })
        
          if (error)
              this._snackBar.open('This import contains errors', "close", { verticalPosition: 'top', duration: 5000 });
          else if (warning)
            this._snackBar.open('This import contains warnings', "close", { verticalPosition: 'top', duration: 5000 });
          
          if (!error)
          {
            this.importIsValid = true;
            this._snackBar.open("File is valid, records can be imported" , "close", { duration: 5000 });
          }

          this.isLoading = false;

    }).catch((error) => {
        console.log(error);
        this.isLoading = false;
    } );
  }

  }
}

executeImport () : void {
  let fileToUpload = this.file;
  const formData = new FormData();
  if (fileToUpload !== null && fileToUpload !== undefined) {
      var userId = this.authenticationService.getUserId();
      formData.append('file', fileToUpload, fileToUpload.name);      
      this.service.importTransactions(this.isPrivate, formData, Number(userId)).then( (data) => {
          console.log(data);
          this.validationSource = data as TransactionDto[];       
          this.dataSource = new MatTableDataSource(this.validationSource);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.importIsValid = false;
          this._snackBar.open(`Successfully imported ${(data as TransactionDto[]).length} records.`, "close", { duration: 5000 });
  }).catch((error) => {
      console.log(error);
  } );
}
}

formatDescription(description: string) : string  {
  if (description === null || description === undefined)
    return '';
  return ' ' + description.trim();
}

replaceDecimalSign(inpS:string) : string {
  if (inpS === undefined || inpS === null)
    return inpS;
  return inpS.replace(',', '.');
}

userHasRole() : boolean {
  return this.authenticationService.isUserInRole(Roles.privateBank);  
}

accountExists(accountNumber: string) : boolean {
  return false;
}

refreshLists() : void {
  this.isLoading = true;
  this.listsService.getBankLists(this.isPrivate);

  setTimeout(() => {   
    this.isLoading = false;
    this.initLists();
  }, 1000); 
}

changeConnection (event: MatCheckboxChange)
{
  this.authenticationService.setPrivateBank(this.isPrivate);
  this.refreshLists();
}

ngAfterViewInit() { 
  this.accordion.openAll();        
  this.matSelectAccount.valueChange.subscribe(value => {
      this.searchData.accountId = value;
  });  
  
  this.matSelectCategory.valueChange.subscribe(value => {
    this.searchData.categoryId = value;
});    
}

initLists() {  
    var dataAccounts = this.listsService.getAccountList();

    if (!this.commonService.stringIsNullOrEmpty(dataAccounts))
    {
      var orgAccountList = JSON.parse(dataAccounts || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
      this.accounts = orgAccountList as SimpleObject[];
    }

    var dataCategories = this.listsService.getCategoryList();

    if (!this.commonService.stringIsNullOrEmpty(dataCategories))
    {
      var orgCategoryList = JSON.parse(dataCategories || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
      this.categories = orgCategoryList as SimpleObject[];
    }
}

addDateFromEvent(event: MatDatepickerInputEvent<Date>) {
  if (event.value !== null)
  {
    var dt = new Date(event.value.toString());
    let UTCDate = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) - dt.getTimezoneOffset();
    this.searchData.dateFrom = new Date(UTCDate);    
  }   
 }

 addDateToEvent(event: MatDatepickerInputEvent<Date>) {
  if (event.value !== null)
  {
    var dt = new Date(event.value.toString());
    let UTCDate = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) - dt.getTimezoneOffset();
    this.searchData.dateTo = new Date(UTCDate);    
  }   
}

clearSearchFields() {
  this.searchData = {} as TransactionSearch;
  this.matSelectCategory.options.forEach((data: MatOption) => data.deselect());  
  this.matSelectAccount.options.forEach((data: MatOption) => data.deselect());  
  this.dataSource = new MatTableDataSource([] as TransactionDto[]);
}

search()
  {
    this.isLoading = true;

      this.searchData.results = this.numberResultsComponent.getSelectedValue();
      this.searchData.isPrivateAccount = this.isPrivate;
      this.service.search(this.searchData)
    .subscribe(response => {
        this.dataSource = new MatTableDataSource(response.body as TransactionDto[]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.accordion.closeAll();
        this.isLoading = false;
        }, error => {
            alert('searchTransactions: An unexpected error occurred.');
            this.isLoading = false;
            console.log(error);
        });
  }  

edit(transaction: TransactionDto) {
    this.changeTransactionComponent.bind(transaction, this.drawer);
}

addNew () {

}

announceSortChange(sortState: Sort) {
  // This example uses English messages. If your application supports
  // multiple language, you would internationalize these strings.
  // Furthermore, you can customize the message to add additional
  // details about the values being sorted.
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
}

formatDate(srcDate: Date) {
  return this.commonService.formatToNlDisplayDate(srcDate);
}


}
