<mat-card style="width: 530px;">
    <mat-card-content>
    <table class="example-full-width" cellspacing="0">
        <th></th>
        <th></th>
        <tr>
            <td>
                <mat-form-field appearance="outline">
                    <mat-label>Customer</mat-label>
                    <mat-select #selectedCustomerId (valueChange)="selectedCustomerChanged()">
                      <mat-option *ngFor="let customer of customers" [value]="customer.id">
                        {{customer.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="!isCustomerSelected()">Customer required</mat-hint>
                  </mat-form-field>
            </td>            
            <td>
                <mat-form-field appearance="outline">
                    <mat-label>Date invoice</mat-label>
                    <input matInput [matDatepicker]="picker3" [(ngModel)]="invoice.invoiceDate" (valueChange)="allRequiredFieldsFilled()">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                    <mat-hint *ngIf="invoice.invoiceDate === null || invoice.invoiceDate === undefined">Date required</mat-hint>
                </mat-form-field>
            </td>
        </tr>
        <tr>  
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Invoice number</mat-label>
                    <input matInput placeholder="description" type="text" [(ngModel)]="invoice.invoiceNumber" (change)="allRequiredFieldsFilled()">
                    <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(invoice.invoiceNumber)">Invoice number required</mat-hint>
                </mat-form-field>
            </td>         
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Fiscal year</mat-label>
                    <mat-select [(value)]="selectedFiscalYear" [required]="true" (valueChange)="selectedFiscalYearChanged()">
                      <mat-option *ngFor="let fiscalYear of fiscalYears" [value]="fiscalYear.id">
                        {{fiscalYear.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </td>
        </tr>
        <tr>          
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Pay term</mat-label>
                    <mat-select [(value)]="selectedTerm" (valueChange)="selectedTermChanged()">
                      <mat-option *ngFor="let term of terms" [value]="term.id">
                        {{formatTerms(term.name)}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Sender</mat-label>
                    <mat-select [(value)]="selectedSender" [required]="true" (valueChange)="selectedSenderChanged()">
                      <mat-option *ngFor="let sender of senders" [value]="sender.id">
                        {{sender.name}}
                      </mat-option>
                    </mat-select>                   
                  </mat-form-field>
            </td>
        </tr>     
        <tr>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Number (hours)</mat-label>
                    <input matInput placeholder="Number (hours)" type="text" [(ngModel)]="invoice.noHours" [required]="true" (change)="calculatePrices()">
                    <mat-hint *ngIf="!this.commonService.numberGreaterZero(invoice.noHours)">Number > 0 required</mat-hint>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Amout (excl. VAT)</mat-label>
                    <input matInput type="number" placeholder="Amount (excl. VAT)" [(ngModel)]="invoice.amount" [readonly]="true">
                    <span matTextPrefix>€&nbsp;</span>
                </mat-form-field>
            </td>
            
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Rate</mat-label>                    
                    <input matInput placeholder="Rate" type="number" [(ngModel)]="invoice.hourlyRate" (change)="setTwoNumberDecimal()" min="0" step="0.25" value="0.00"  />
                    <span matTextPrefix>€&nbsp;</span>
                    <mat-hint *ngIf="!this.commonService.stringNumberGreaterZero(invoice.hourlyRate)">Number > 0 required</mat-hint>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Total VAT</mat-label>
                    <input matInput type="number" placeholder="Rate" [(ngModel)]="invoice.tax" [readonly]="true">
                    <span matTextPrefix>€&nbsp;</span>
                </mat-form-field>
            </td>
            
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>VAT percentage</mat-label>
                    <mat-select [(value)]="selectedVatPercentage" (valueChange)="selectedVatPercentageChanged()">
                      <mat-option *ngFor="let percentage of percentages" [value]="percentage.id">
                        {{formatVatPercentage(percentage.name)}}
                      </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="selectedVatPercentage === 0">Vat percentage required</mat-hint>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Total amout</mat-label>
                    <input matInput placeholder="Total amount" type="text" [(ngModel)]="invoice.totalAmount" [readonly]="true">
                    <span matTextPrefix>€&nbsp;</span>
                </mat-form-field>
            </td>
        </tr>
        <tr> 
            <td colspan="2" rowspan="2">
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Invoice description</mat-label>
                    <input matInput placeholder="Description" type="text" [(ngModel)]="invoice.description" required (change)="allRequiredFieldsFilled()">
                    <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(invoice.description)">Description required</mat-hint>
                </mat-form-field>
            </td>
        </tr>
        <tr></tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Date invoice payed</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="invoice.payedDate">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </td>
            <td>
                <mat-checkbox class="chkboxInleftdiv nextRowItems" [(ngModel)]="invoice.fullyPayed">Fully payed</mat-checkbox>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Attached invoice</mat-label>
                    <input matInput placeholder="Attached invoice" type="text" [(ngModel)]="invoice.filename" [readonly]="true">
                </mat-form-field>
            </td>
        </tr>
    </table>
    <div style="float:left;">
        <button [disabled]="!allFieldsFilled" mat-mini-fab matTooltip="Warn" style="margin-left: 10px;" (click)="generateInvoice()" color="warn" ngbTooltip="generate invoice">
            <mat-icon>open_in_new</mat-icon>
        </button>  
        <upload-invoice style="margin-left:20px;"></upload-invoice>                 
    </div>
    <div class="searchButton">
        <button mat-raised-button class="leftActionButton" (click)="closeDrawer()">cancel</button>             
        <button mat-raised-button class="actionButton" [disabled]="!allFieldsFilled" (click)="save()">save</button>             
    </div>
    </mat-card-content>
</mat-card>