import { LiveAnnouncer} from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TimesheetDto, TimesheetSearch } from 'src/app/models/timesheet';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSelect } from '@angular/material/select';
import { MatAccordion } from '@angular/material/expansion';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { MatDrawer } from '@angular/material/sidenav';
import { ChangeTimesheetComponent } from './change-timesheet/change-timesheet.component';
import { MatOption } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';
import { SimpleObject } from 'src/app/models/simple-object';
import { LegitAutocompleteComponent } from '../common/controls/legit-autocomplete/legit-autocomplete.component';
import { NumberResultsComponent } from '../common/controls/number-results/number-results.component';
import { ListsService } from '../services/lists.service';
import { CommonService } from '../services/common.service';

const tsArr: TimesheetDto[] = [];

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})

export class TimesheetComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id','date','user','task', 'description','noHours', 'actions'];
  dataSource = new MatTableDataSource(tsArr);
  isLoading = false;
  searchData = {} as TimesheetSearch;
  users!: User[];
  
  projects!:SimpleObject[];
  tasks!:SimpleObject[];

  constructor(private _liveAnnouncer: LiveAnnouncer, private timesheetService: TimesheetService, private listsService: ListsService, private authService: AuthenticationService, private service: TimesheetService, private dialogServie: DialogService, private _snackBar: MatSnackBar, private commonService: CommonService) {  
    this.initLists();
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('selectedUserId') matSelectUser!: MatSelect;
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('drawerContainer') drawerContainer!: MatDrawerContainer;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild(ChangeTimesheetComponent) changeTimesheetComponent!: ChangeTimesheetComponent;
  @ViewChild(NumberResultsComponent) numberResultsComponent!: NumberResultsComponent;

  @ViewChild('taskAutoComplete') taskAutoComplete!: LegitAutocompleteComponent;
  @ViewChild('projectAutoComplete') projectAutoComplete!: LegitAutocompleteComponent;


  initLists() {
      var dataTasks = this.listsService.getTaskList();

      if (!this.commonService.stringIsNullOrEmpty(dataTasks))
      {
        var orgTaskList = JSON.parse(dataTasks || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
        this.tasks = orgTaskList as SimpleObject[];
      }

      var dataProjects = this.listsService.getProjectList();
      
      if (!this.commonService.stringIsNullOrEmpty(dataProjects))
      {
        var orgProjectList = JSON.parse(dataProjects || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
        this.projects = orgProjectList as SimpleObject[];
      }
   }

  async ngAfterViewInit() {   
    this.accordion.openAll();        
    this.matSelectUser.valueChange.subscribe(value => {
        this.searchData.userId = value;
    });       
  }

  clearSearchFields() {
    this.searchData = {} as TimesheetSearch;
    this.matSelectUser.options.forEach((data: MatOption) => data.deselect());
    this.taskAutoComplete.clearSelecedItem();
    this.projectAutoComplete.clearSelecedItem();
    this.dataSource = new MatTableDataSource([] as TimesheetDto[]);
  }

  search()
  {    
      this.isLoading = true;
      var id = this.searchData.id == null ? '' : this.searchData.id.toString();
      
      var task = this.taskAutoComplete.getSelectedItem();
      var taskId = task.id == 0 ? '' : task.id.toString();
      
      var project = this.projectAutoComplete.getSelectedItem();
      var projectId = project.id == 0 ? '' : project.id.toString();

      var description = this.searchData.description == null ? '' : this.searchData.description
      var userId = this.searchData.userId == null ? '' : this.searchData.userId.toString();

      var numberResults = this.numberResultsComponent.getSelectedValue();

      this.service.search(id, description, taskId, userId, projectId, numberResults)
    .subscribe(response => {
        this.dataSource = new MatTableDataSource(response as TimesheetDto[]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.accordion.closeAll();
        this.isLoading = false;
        }, error => {
            alert('searchTimesheets: An unexpected error occurred.');
            this.isLoading = false;
            console.log(error);
        });       
  }

  ngOnInit(): void {
    this.service.timesheetChanged.subscribe(timesheet => {
      const index = this.dataSource.data.findIndex((item) => item.id === timesheet.id);
      if (index > -1) {
        this.dataSource.data[index] = timesheet;
        this.dataSource.paginator = this.paginator;
    }
      this.drawer.close();
      this._snackBar.open("Timesheet saved." , "close", { duration: 5000 });
    });
    
    this.service.timesheetNew.subscribe(timesheet => {
      var data = this.dataSource.data;
      data.push(timesheet);
      //this.dataSource.data = data;

      this.dataSource = new MatTableDataSource(data as TimesheetDto[]);

      //this.dataSource.data.push(timesheet);
      this.dataSource.paginator = this.paginator;    
      this.dataSource.sort = this.sort;

      this.drawer.close();
      this._snackBar.open("Timesheet created." , "close", { duration: 5000 });
    });

    this.service.timesheetError.subscribe(message => {    
      this._snackBar.open(message, "close", { verticalPosition: 'top'});
    });

    this.service.timesheetDeleted.subscribe(id => {    
      var deletedElementIdx = this.dataSource.data.findIndex(t => t.id === id);
      var data = this.dataSource.data;         
      this.dataSource.data = data.filter((i, index) => index !== deletedElementIdx);   ;
      this.dataSource.paginator = this.paginator;    
      this.dataSource.sort = this.sort;

      this._snackBar.open("Timesheet deleted.", "close", { duration: 5000 });
    });

     this.authService.getUsers().subscribe(response => {
         this.users = response as User [];   
         var loggedInUserId = Number(this.authService.getUserId());
         this.matSelectUser.value = loggedInUserId;
         this.searchData.userId = loggedInUserId;
     });          

  }

  formatDate(srcDate: Date) {
    return new Date(srcDate).toLocaleDateString('nl-NL');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addNew() {
    this.changeTimesheetComponent.new(this.drawer);
    var task = this.taskAutoComplete.getSelectedItem();
    if (task.id !== 0)
      this.changeTimesheetComponent.setDefaultTask(task.name);
  }

  delete(id: any) {
    //console.log('delete pressed for: ' + id);
    this.dialogServie.confirmDialog({
      title: 'Confirm please',
      message: 'Are you sure you want to delete timesheet ' + id + '?',
      confirmText: 'Yes',
      cancelText: 'No'
    }).subscribe(data => 
      { 
        if (data == true)
        {
          console.log('DELETE: ' + id);
          this.timesheetService.delete(id);
        }
        else
          console.log('do not delete');
      });    
  }

  edit(timesheet: TimesheetDto) {
      this.changeTimesheetComponent.bind(timesheet, this.drawer);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }


}
