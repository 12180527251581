import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { TaskDto, TaskSearch, TaskUpdate } from '../models/task';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private baseUrl = environment.baseUrl + '/task';
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  //opts = [];

  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient,) { }

  taskError: EventEmitter<string> = new EventEmitter<string>();
  taskChanged: EventEmitter<TaskDto> = new EventEmitter<TaskDto>();
  taskNew: EventEmitter<TaskDto> = new EventEmitter<TaskDto>();
  taskDeleted: EventEmitter<number> = new EventEmitter();

  getById(id: string) {    
    var taskGetById = '/getbyid?id=' + id;
    return this.http.get(this.baseUrl + taskGetById) .pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    }));
  }

  search(searchData: TaskSearch) {
    var projectSearchUrl = 
   //'?id=' + (searchData.id === undefined ? '' : searchData.id)
    '?name=' + (searchData.name === undefined ? '' : searchData.name)
    + '&customerId=' + (searchData.customerId === undefined ? '' : searchData.customerId)
    + '&projectId=' + (searchData.projectId === undefined ? '' : searchData.projectId)
    + '&results=' + searchData.results;
    return this.http.get(this.baseUrl + projectSearchUrl) .pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    }));
  }

  delete(id: string) {  
    return this.http.delete(this.baseUrl + '/' + Number(id), {headers: this.headers}).subscribe((response) => {      
      this.taskDeleted.emit(Number(response));
      console.log('Task deleted: ' + response);
    });
  }  

  async update(task: TaskDto) { 
    const body: TaskUpdate =  {
      "id": task.id, 
      "name": task.name, 
      "description": task.description,  
      "projectId": task.projectId,          
      "noHours":task.noHours,
      "percentageCompleet":task.percentageCompleet,
      "dateFinished":task.dateFinished,
      "creationUserId": 0, 
      "changeUserId": task.changeUserId,
    }
    const source$ = this.http.put(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));    
    this.taskChanged.emit(firstResult as TaskDto);   
  }

  async create(task: TaskDto) { 
    const body: TaskUpdate =  {
      "id": 0, 
      "name": task.name, 
      "description": task.description,  
      "projectId": task.projectId,          
      "noHours":task.noHours,
      "percentageCompleet":task.percentageCompleet,
      "dateFinished":task.dateFinished,
      "creationUserId": task.creationUserId, 
      "changeUserId":0,
    }
    const source$ = this.http.post(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));    
    this.taskNew.emit(firstResult as TaskDto); 
  }

  async getList() {     
    const source$ = this.http.get(this.baseUrl + '/gettasklist');
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));        
    return firstResult;
  }

    handleError(error: HttpErrorResponse) {
      this.taskError.emit('Task-service error: ' + error.error);
    }

}
