import { LiveAnnouncer} from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { TaskService } from 'src/app/services/task.service';
import { TaskDto,TaskSearch } from 'src/app/models/task';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSelect } from '@angular/material/select';
import { MatAccordion } from '@angular/material/expansion';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { MatDrawer } from '@angular/material/sidenav';
import { ChangeTaskComponent } from './change-task/change-task.component';
import { MatOption } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NumberResultsComponent } from 'src/app/common/controls/number-results/number-results.component';
import { SimpleObject } from 'src/app/models/simple-object';
import { LegitAutocompleteComponent } from 'src/app/common/controls/legit-autocomplete/legit-autocomplete.component';
import { ListsService } from 'src/app/services/lists.service';
import { CommonService } from 'src/app/services/common.service';

const taskArr: TaskDto[] = [];

@Component({
  selector: 'admin-tasks',
  templateUrl: './admin-tasks.component.html',
  styleUrls: ['./admin-tasks.component.css']
})

export class AdminTasksComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ['id','name','description','customerName','projectName', 'dateFinished', 'actions'];
  dataSource = new MatTableDataSource(taskArr);
  isLoading = false;
  searchData = {} as TaskSearch;
  customers!: SimpleObject[];
  projects!:SimpleObject[];

  constructor(private _liveAnnouncer: LiveAnnouncer, private listsService: ListsService, private service: TaskService, private dialogServie: DialogService, private _snackBar: MatSnackBar, private commonService: CommonService) {
    this.initLists();
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('selectedCustomerId') matSelectCustomer!: MatSelect;
  @ViewChild('selectedProjectId') matSelectProject!: MatSelect;
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('drawerContainer') drawerContainer!: MatDrawerContainer;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild(ChangeTaskComponent) changeTaskComponent!: ChangeTaskComponent;
  @ViewChild(NumberResultsComponent) numberResultsComponent!: NumberResultsComponent;
  @ViewChild('projectAutoComplete') projectAutoComplete!: LegitAutocompleteComponent;

  initLists() {
    var dataCustomers = this.listsService.getCustomerList();

    if (!this.commonService.stringIsNullOrEmpty(dataCustomers))
    {
      var orgTCustomerList = JSON.parse(dataCustomers || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
      this.customers = orgTCustomerList as SimpleObject[];
    }

    var dataProjects = this.listsService.getProjectList();
      
      if (!this.commonService.stringIsNullOrEmpty(dataProjects))
      {
        var orgProjectList = JSON.parse(dataProjects || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
        this.projects = orgProjectList as SimpleObject[];
      }
 }

 ngOnInit(): void {
  this.service.taskChanged.subscribe(task => {
    const index = this.dataSource.data.findIndex((item) => item.id === task.id);
    if (index > -1) {
      this.dataSource.data[index] = task;
      this.dataSource.paginator = this.paginator;
  }
    this.drawer.close();
    this._snackBar.open("Task saved." , "close", { duration: 5000 });
    this.listsService.updateTaskList(task.id, task.name);
  });
  
  this.service.taskNew.subscribe(task => {
    this.dataSource.data.push(task);
    this.dataSource.paginator = this.paginator;      
    this.drawer.close();
    this._snackBar.open("Task created." , "close", { duration: 5000 });
    this.listsService.updateTaskList(task.id, task.name);
  });

  this.service.taskError.subscribe(message => {    
    this._snackBar.open(message, "close", { verticalPosition: 'top'});
  });   
  
  this.service.taskDeleted.subscribe(id => {    
    var deletedElementIdx = this.dataSource.data.findIndex(t => t.id === id);
    var data = this.dataSource.data;         
    this.dataSource.data = data.filter((i, index) => index !== deletedElementIdx);   ;
    this.dataSource.paginator = this.paginator;    
    this.dataSource.sort = this.sort;

    this._snackBar.open("Task deleted.", "close", { duration: 5000 });
    this.listsService.deleteFromTaskList(id);
  });  
}

  ngAfterViewInit() {
     this.accordion.openAll();

     this.matSelectCustomer.valueChange.subscribe(value => {
        this.searchData.customerId = value;
      });

      this.matSelectProject.valueChange.subscribe(value => {
        this.searchData.projectId = value;
      });
  }

  clearSearchFields() {
    this.searchData = {} as TaskSearch;
    this.matSelectCustomer.options.forEach((data: MatOption) => data.deselect());
    this.matSelectProject.options.forEach((data: MatOption) => data.deselect());
    this.dataSource = new MatTableDataSource([] as TaskDto[]);
  }

  search() {
    this.isLoading = true;
      //var projectId = this.searchData.projectId == null ? '' : this.searchData.projectId.toString();
      //var customerId = this.searchData.customerId == null ? '' : this.searchData.customerId.toString();
      //var name = this.searchData.name == null ? '' : this.searchData.name
      this.searchData.results = this.numberResultsComponent.getSelectedValue();

       this.service.search(this.searchData)
    .subscribe(response => {
        this.dataSource = new MatTableDataSource(response as TaskDto[]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.accordion.closeAll();
        this.isLoading = false;
        }, error => {
            alert('searchPTasks: An unexpected error occurred.');
            this.isLoading = false;
            console.log(error);
        });
  }  
  
  addNew() {
    this.changeTaskComponent.new(this.drawer);
  }

  delete(id: any) {    
    this.dialogServie.confirmDialog({
      title: 'Confirm please',
      message: 'Are you sure you want to delete task ' + id + '?',
      confirmText: 'Yes',
      cancelText: 'No'
    }).subscribe(data => 
      { 
        if (data == true)
          this.service.delete(id);
        else
          console.log('do not delete');
      });    
  }

  edit(task: TaskDto) {
     this.changeTaskComponent.bind(task, this.drawer);
  }

  formatDate(srcDate: Date) {
    return srcDate === null ? '' : new Date(srcDate).toLocaleDateString('nl-NL');
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
