<mat-drawer-container class="edit-invoice-container" autosize hasBackdrop="true" disableClose="true" position="end">
    <mat-drawer #drawer class="invoice-sidenav" mode="over">    
      <change-invoice></change-invoice>
    </mat-drawer>  
  <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel class="searchPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            search
          </mat-panel-title>
          <mat-panel-description>
            enter search criteria
            <mat-icon>search</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>  

        <table class="full-width" cellspacing="0">
          <tr>
            <td class="firstSearchColumn">
              <mat-form-field appearance="outline" >
                <mat-label>Description</mat-label>
                <input matInput [(ngModel)]="searchData.description">
              </mat-form-field>
            </td>
            <td class="secondSearchColumn">
              <mat-form-field appearance="outline">
                <mat-label>Customer</mat-label>
                <mat-select #selectedCustomerId>
                  <mat-option *ngFor="let customer of customers" [value]="customer.id">
                    {{customer.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td class="thirdSearchColumn">
              <number-results></number-results>
            </td>
            <td class="fourthSearchColumn"></td>
          </tr>
          <tr>
            <td>
              <mat-form-field appearance="outline" class="secondRowSearchItems">
                <mat-label>Invoice number</mat-label>
                <input matInput [(ngModel)]="searchData.invoiceNumber">
              </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="secondRowSearchItems">
                    <mat-label>Invoice from date</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="searchData.invoiceDateFrom" (dateChange)="addDateFromEvent($event)">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="secondRowSearchItems">
                    <mat-label>Invoice to date</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="searchData.invoiceDateTo" (dateChange)="addDateToEvent($event)">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </td>
            <td >
                <div class="searchButtonsInvoices">
                    <button mat-raised-button (click)="clearSearchFields();" class="leftActionButton">clear</button>             
                    <button mat-raised-button (click)="search()" class="actionButton">search</button>             
                </div>
            </td>
          </tr>        
        </table>        
      </mat-expansion-panel>        
    </mat-accordion>
  
  <div class="mat-elevation-z8 tableContent">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

        <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
              Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by invoice number">
            Number
            </th>
            <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}}</td>
         </ng-container>
          
          <ng-container matColumnDef="invoiceDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by invoicedate">
              Date
              </th>
              <td mat-cell *matCellDef="let element"> {{ formatDate(element.invoiceDate)}} </td>
          </ng-container>
          
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer">
            Customer
            </th>
            <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
        </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description">
            Description
            </th>
            <td mat-cell *matCellDef="let element"> {{element.description}}</td>
        </ng-container>

        <ng-container matColumnDef="payedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by pay date">
            Payed
            </th>
            <td mat-cell *matCellDef="let element"> {{formatDate(element.payedDate)}}</td>
        </ng-container>              

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by amount">
            Amount
            </th>
            <td mat-cell *matCellDef="let element"> {{element.amount | currency:'EUR':'symbol-narrow'}}</td>
        </ng-container>
        
        <ng-container matColumnDef="tax">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tax">
            Tax
            </th>
            <td mat-cell *matCellDef="let element"> {{element.tax | currency:'EUR':'symbol-narrow'}}</td>
        </ng-container>
        
        <ng-container matColumnDef="totalAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by total amount">
            Total
            </th>
            <td mat-cell *matCellDef="let element"> {{element.totalAmount | currency:'EUR':'symbol-narrow'}}</td>
        </ng-container>
        
        <ng-container matColumnDef="fullyPayed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by fully payed">
            Fully payed
            </th>
            <td mat-cell *matCellDef="let element"> {{element.fullyPayed}}</td>
        </ng-container>

          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>              
                  <mat-icon (click)="addNew()" aria-label="Example icon-button with a heart icon" ngbTooltip="create invoice" class="actionIcon">add</mat-icon>              
                </th>
        
              <td mat-cell *matCellDef="let element; let i=index;">
                  <mat-icon (click)="edit(element)" aria-label="Edit" class="actionIcon" ngbTooltip="edit invoice">edit</mat-icon>
                  <mat-icon (click)="delete(element['id'])" aria-label="Delete" ngbTooltip="delete invoice" class="actionIcon" color="warn">delete</mat-icon>
                  <mat-icon (click)="download(element)" aria-label="Download" ngbTooltip="download invoice" class="actionIcon" [ngClass]="{'disable':checkInvoice(element) !== true}">download</mat-icon>
                </td>
            </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
          <mat-progress-spinner    
              mode="indeterminate">
          </mat-progress-spinner>
      </div>
      <mat-paginator [pageSize]="15" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
  </div> 
</mat-drawer-container>