import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { TimesheetUpdate, TimesheetDto } from '../models/timesheet';

@Injectable({
  providedIn: 'root'
})

export class TimesheetService {
  private baseUrl = environment.baseUrl + '/timesheet';
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  timesheetError: EventEmitter<string> = new EventEmitter<string>();
  timesheetChanged: EventEmitter<TimesheetDto> = new EventEmitter<TimesheetDto>();
  timesheetNew: EventEmitter<TimesheetDto> = new EventEmitter<TimesheetDto>();
  timesheetDeleted: EventEmitter<number> = new EventEmitter();

  constructor(private http: HttpClient, private autService: AuthenticationService) { }

  search(id: string, description: string, taskId: string, userId: string, projectId: string, numberResults: number) {    
    var timesheetSearchUrl = 
    '?id=' + id 
    + '&description=' + description 
    + '&taskId=' + taskId 
    + '&userId=' + userId 
    + '&projectId=' + projectId
    + '&results=' + numberResults;

    return this.http.get(this.baseUrl + timesheetSearchUrl) .pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    }));
  }

  async update(timesheet: TimesheetDto) { 

    const body: TimesheetUpdate =  {
      "id": timesheet.id, 
      "date": timesheet.date,
      "description": timesheet.description, 
      "taskId": timesheet.taskId,
      "userId": timesheet.userId, 
      "creationUserId": 0, 
      "noHours": timesheet.noHours,
      "changeUserId": timesheet.changeUserId
    };

    const source$ = this.http.put(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));    
    this.timesheetChanged.emit(firstResult as TimesheetDto);   
  }
    
    handleError(error: HttpErrorResponse) {
      this.timesheetError.emit('Timesheet-service error: ' + error.error);
    }

  async create(timesheet: TimesheetDto) { 
    const body: TimesheetUpdate =  {
      "id": 0, 
      "date": timesheet.date,
      "description": timesheet.description, 
      "taskId": timesheet.taskId,
      "userId": timesheet.userId, 
      "creationUserId": timesheet.creationUserId, 
      "noHours": timesheet.noHours,
      "changeUserId": 0
    };

    const source$ = this.http.post(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch(this.handleError);
    this.timesheetNew.emit(firstResult as TimesheetDto); 
  }

  delete(id: string) {  
    return this.http.delete(this.baseUrl + '/' + Number(id), {headers: this.headers}).subscribe((response) => {      
      this.timesheetDeleted.emit(Number(response));
      console.log('Timesheet deleted: ' + response);
    });
  }  
}
