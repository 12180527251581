import { Component } from '@angular/core';

interface Options {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'number-results',
  templateUrl: './number-results.component.html',
  styleUrls: ['./number-results.component.css']
})

export class NumberResultsComponent {  
  numbers: Options[] = [
    {value: 10, viewValue: '10'},
    {value: 50, viewValue: '50'},
    {value: 100, viewValue: '100'},
    {value: 500, viewValue: '500'},
  ];
  
  selectedNumber = this.numbers[1].value;

 constructor() {  }

getSelectedValue():number {
    return Number(this.selectedNumber);
}

clear () {
  this.selectedNumber = this.numbers[1].value;
}

}
