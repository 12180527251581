import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Status } from 'src/app/models/status';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService} from 'src/app/services/authentication.service'
import { ListsService } from '../services/lists.service';
import { Roles } from '../common/enums'

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  status!:Status;
    
  constructor(private formBuilder: FormBuilder, 
    private http: HttpClient,
    private router: Router,
    private authService: AuthenticationService,
    private listsService: ListsService) {
      this.form = this.formBuilder.group({
        username: '',  
        password: ''
      });
      //console.log('constr login');
    }

      ngOnInit(): void {
        //console.log('init login');
        if(this.authService.isLoggedIn()){
          this.router.navigate(['./home']);
        }

      }
    
      onPost(): void {
        this.status = {statusCode:0,message:"wait...."};

          // console.log(this.form.value);

          this.authService.login(this.form.value).subscribe({
            next: (res)=>{
              // save username, accesstoken and refresh token into localStorage
              this.authService.addAccessToken(res.token);
              this.authService.addRefreshToken(res.refreshToken);
              this.authService.addUsername(res.username);
              this.authService.addFullName(res.name);
              this.authService.addUserId(res.userId);
              //this.authService.addPrivateBank(false);
              let fillBankLists = this.authService.isUserInRole(Roles.bank);
              this.listsService.fillLists(fillBankLists);
              this.status.statusCode = res.statusCode;
              this.status.message=res.message;
              if(res.statusCode == 1){
                this.router.navigate(['./home']);
              }      
            },
            error: (err)=>{
              console.log(err);
              console.log(err.status);
              this.status.statusCode=0;
              
              if (err.status == 401)
                  this.status.message = err.error;
              else        
                this.status.message="some error on server side";
            }
          })
      }
    }
